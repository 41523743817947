
import React from "react";
import "./Homepage.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (<div>
<Helmet>
	<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7001854000154125"
     crossorigin="anonymous"></script>
        <title>Lord-a-PDF | Free Online PDF Tools</title>
        <meta name="description" content="Lord-a-PDF offers free, fast, and easy PDF tools. Merge, split, compress, protect, add watermarks, and extract text from PDFs online without login." />
        <meta name="keywords" content="pdf tools, free pdf editor, online pdf tools, merge pdf, split pdf, compress pdf, lord-a-pdf" />
        <meta property="og:title" content="Lord-a-PDF | Free Online PDF Tools" />
        <meta property="og:description" content="Merge, split, compress, protect, and add watermarks to PDFs online. Fast, free, and secure!" />
        <meta property="og:image" content="https://www.yourwebsite.com/assets/og-image.png" />
        <meta property="og:url" content="https://www.yourwebsite.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Lord-a-PDF | Free Online PDF Tools" />
        <meta name="twitter:description" content="Merge, split, compress, protect, and add watermarks to PDFs online. Fast, free, and secure!" />
        <meta name="twitter:image" content="https://www.yourwebsite.com/assets/og-image.png" />
      </Helmet>

  	<>
<div id="header">
        <h1>Lord-a-PDF</h1>
        <h5>Free &#9900; Fast &#9900; Easy &#9900; No Login/Signup </h5>
        <h6><i> (basically a lord)</i></h6>
    </div>
    <div id="content">
        <div className="homepage">
      
      <div className="features" >
        <div className="feature" onClick={() => handleNavigation("/merge")}>
          <h2>Merge PDFs</h2>
          <p>Combine multiple PDFs into one</p>
        </div>
        <div className="feature" onClick={() => handleNavigation("/split")}>
          <h2>Split PDFs</h2>
          <p>Separate a PDF into multiple files</p>
        </div>
        <div className="feature" onClick={() => handleNavigation("/watermark")}>
          <h2>Add Watermark</h2>
          <p>Protect your PDF with a watermark</p>
        </div>
        <div className="feature" onClick={() => handleNavigation("/compress")}>
          <h2>Compress PDFs</h2>
          <p>Reduce the size of your PDF</p>
        </div>
        <div className="feature" onClick={() => handleNavigation("/ocr")}>
          <h2>OCR PDFs</h2>
          <p>Extract text from scanned PDFs</p>
        </div>
        <div className="feature" onClick={() => handleNavigation("/protect")}>
          <h2>Protect PDFs</h2>
          <p>Encrypt your PDF with a password</p>
        </div>
      </div>
      
    </div>
    </div>
    <div id="footer">
        <p>&copy; 2024 IndianMadeSOL </p>
    </div>
</></div>);
};

export default Homepage;
