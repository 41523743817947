
import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import MergePage from "./components/MergePage";
import SplitPage from "./components/SplitPage";
import WatermarkPage from "./components/WatermarkPage";
import CompressPage from "./components/CompressPage";
import OCRPage from "./components/OCRPage";
import ProtectPage from "./components/ProtectPage";


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/merge" element={<MergePage />} />
      <Route path="/split" element={<SplitPage />} />
      <Route path="/watermark" element={<WatermarkPage />} />
      <Route path="/compress" element={<CompressPage />} />
      <Route path="/ocr" element={<OCRPage />} />
      <Route path="/protect" element={<ProtectPage />} />
    </Routes>
  );
};

export default App;
