import React, { useState } from "react";
import axios from "axios";
import "./OperationPage.css";
import { Helmet } from "react-helmet";

const OCRPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [ocrText, setOcrText] = useState("");
  const [loading, setLoading] = useState(false);
   const [isNavOpen, setIsNavOpen] = useState(false);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".pdf")) {
      setSelectedFile(file);
      setError("");
    } else {
      setError("Only PDF files are allowed.");
    }
  };

  const handleExtractText = async () => {
    if (!selectedFile) {
      setError("Please select a PDF file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setLoading(true); // Show loader
    try {
      const uploadResponse = await axios.post("https://api.lordapdf.com/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (uploadResponse.status === 200 && uploadResponse.data.file_id) {
        const uploadedFileId = uploadResponse.data.file_id;

        const response = await axios.post("https://api.lordapdf.com/ocr", {
          file_id: uploadedFileId,
        });

        if (response.status === 200 && response.data.text) {
          setOcrText(response.data.text);
         // alert("Text extracted successfully!");
        } else {
          throw new Error("OCR operation failed.");
        }
      } else {
        throw new Error("File upload failed.");
      }
    } catch (error) {
      setError("Error occurred while extracting text. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(ocrText);
    alert("Text copied to clipboard!");
  };

  return (
    <>

    <Helmet>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7001854000154125"
     crossorigin="anonymous"></script>
  <title>Extract Text from PDFs | OCR | Lord-a-PDF</title>
  <meta
    name="description"
    content="Extract text from scanned PDF documents using OCR technology. Free, fast, and reliable."
  />
  <meta name="keywords" content="ocr pdf, extract text pdf, pdf text recognition, lord-a-pdf" />
  <meta property="og:title" content="Extract Text from PDFs | OCR | Lord-a-PDF" />
  <meta property="og:description" content="Extract text from scanned PDF documents using OCR technology." />
  <meta property="og:image" content="https://www.yourwebsite.com/assets/ocr-og-image.png" />
  <meta property="og:url" content="https://www.yourwebsite.com/ocr" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Extract Text from PDFs | OCR | Lord-a-PDF" />
  <meta name="twitter:description" content="Extract text from scanned PDF documents using OCR technology." />
</Helmet>

      <div id="header">
        <h1>Lord-a-PDF</h1>
        <h5>Free &#9900; Fast &#9900; Easy &#9900; No Login/Signup</h5>
        <h6>
          <i>(basically a lord)</i>
        </h6>
        <nav className="navbar">
          <button className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
            ☰
          </button>
          <ul className={`nav-links ${isNavOpen ? "open" : ""}`}>
            <li><a href="/">Home</a></li>
            <li><a href="/#/merge">Merge</a></li>
            <li><a href="/#/split">Split</a></li>
            <li><a href="/#/watermark">Watermark</a></li>
            <li><a href="/#/protect">Protect</a></li>
            <li><a href="/#/ocr">OCR</a></li>
            <li><a href="/#/compress">Compress</a></li>
          </ul>
        </nav>
      </div>

      <div id="content">
        <div className="operation-page">
          <header className="page-header">
            <h1>OCR PDF</h1>
          </header>
          <div className="main-content">
            <div className="operation-section">
              <p>Upload a scanned PDF to extract text using OCR.</p>
              <label htmlFor="file-upload" className="custom-file-upload">
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".pdf"
                onChange={handleFileSelection}
              />
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              <button onClick={handleExtractText}>Extract Text</button>
              {error && <p className="error">{error}</p>}
              {ocrText && (
                <div className="ocr-output-container">
                  <h3>Extracted Text:</h3>
                  <div className="textarea-container">
                    <textarea
                      className="ocr-textarea"
                      value={ocrText}
                      readOnly
                      rows="10"
                      cols="50"
                    ></textarea>
                    <button className="copy-button" onClick={handleCopyText}>
                      Copy
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div id="footer">
        <p>&copy; 2024 IndianMadeSOL</p>
      </div>
    </>
  );
};

export default OCRPage;
