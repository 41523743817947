import React, { useState } from "react";
import axios from "axios";
import "./OperationPage.css";
import { Helmet } from "react-helmet";

const SplitPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [splitRanges, setSplitRanges] = useState("");
  const [error, setError] = useState("");
  const [splitFiles, setSplitFiles] = useState([]);
  const [loading, setLoading] = useState(false);
   const [isNavOpen, setIsNavOpen] = useState(false);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".pdf")) {
      setSelectedFile(file);
      setError("");
    } else {
      setError("Only PDF files are allowed.");
    }
  };

  const handleSplit = async () => {
    if (!selectedFile) {
      setError("Please select a PDF file.");
      return;
    }

    if (!splitRanges.trim()) {
      setError("Please provide valid split ranges (e.g., 1-2,3-5).");
      return;
    }

    setLoading(true); // Show loader
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const uploadResponse = await axios.post("https://api.lordapdf.com/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (uploadResponse.status === 200 && uploadResponse.data.file_id) {
        const fileId = uploadResponse.data.file_id;

        const response = await axios.post("https://api.lordapdf.com/split", {
          file_id: fileId,
          ranges: splitRanges.split(","),
        });

        if (response.status === 200 && response.data.file_ids) {
          setSplitFiles(response.data.file_ids);
          //alert("File split successfully!");
        } else {
          throw new Error("Split operation failed.");
        }
      } else {
        throw new Error("File upload failed.");
      }
    } catch (error) {
      setError("Error occurred while splitting the file. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleDownload = async (fileId) => {
    setLoading(true); // Show loader
    try {
      const response = await axios.get(`https://api.lordapdf.com/download/${fileId}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError("Error occurred while downloading the file. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (

    <>
    <Helmet>
  <title>Split PDFs Online | Lord-a-PDF</title>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7001854000154125"
     crossorigin="anonymous"></script>
  <meta
    name="description"
    content="Split PDF documents into multiple files based on page ranges. Free, secure, and easy to use."
  />
  <meta name="keywords" content="split pdf, divide pdf, pdf splitter, lord-a-pdf" />
  <meta property="og:title" content="Split PDFs Online | Lord-a-PDF" />
  <meta property="og:description" content="Split PDF documents into multiple files based on page ranges." />
  <meta property="og:image" content="https://www.yourwebsite.com/assets/split-og-image.png" />
  <meta property="og:url" content="https://www.yourwebsite.com/split" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Split PDFs Online | Lord-a-PDF" />
  <meta name="twitter:description" content="Split PDF documents into multiple files based on page ranges." />
</Helmet>

      <div id="header">
        <h1>Lord-a-PDF</h1>
        <h5>Free &#9900; Fast &#9900; Easy &#9900; No Login/Signup</h5>
        <h6>
          <i>(basically a lord)</i>
        </h6>
        <nav className="navbar">
          <button className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
            ☰
          </button>
          <ul className={`nav-links ${isNavOpen ? "open" : ""}`}>
            <li><a href="/">Home</a></li>
            <li><a href="/#/merge">Merge</a></li>
            <li><a href="/#/split">Split</a></li>
            <li><a href="/#/watermark">Watermark</a></li>
            <li><a href="/#/protect">Protect</a></li>
            <li><a href="/#/ocr">OCR</a></li>
            <li><a href="/#/compress">Compress</a></li>
          </ul>
        </nav>
      </div>

      <div id="content">
        <div className="operation-page">
          <header className="page-header">
            <h1>Split PDF</h1>
          </header>
          <div className="main-content">
            <div className="operation-section">
              <p>Split your PDF into smaller documents based on page ranges.</p>
              <label htmlFor="file-upload" className="custom-file-upload">
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".pdf"
                onChange={handleFileSelection}
              />
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              <input
  type="text"
  className={`split-range-input ${error && !splitRanges.trim() ? "input-error" : ""}`}
  placeholder="Enter ranges (e.g., 1-3,5,7-9)"
  value={splitRanges}
  onChange={(e) => setSplitRanges(e.target.value)}
/>

              <button onClick={handleSplit}>Split File</button>
              {error && <p className="error">{error}</p>}
              <div className="uploaded-files-grid">
                {splitFiles.map((fileId, index) => (
                  <div key={index} className="file-item">
                    📄 {fileId}{" "}
                    <button onClick={() => handleDownload(fileId)}>Download</button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div id="footer">
        <p>&copy; 2024 IndianMadeSOL</p>
      </div>
    </>
  );
};

export default SplitPage;
