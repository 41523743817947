import React, { useState } from "react";
import axios from "axios";
import "./OperationPage.css";
import { Helmet } from "react-helmet";

const CompressPage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [fileId, setFileId] = useState("");
  const [loading, setLoading] = useState(false);
   const [isNavOpen, setIsNavOpen] = useState(false);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".pdf")) {
      setSelectedFile(file);
      setError("");
    } else {
      setError("Only PDF files are allowed.");
    }
  };

  const handleCompress = async () => {
    if (!selectedFile) {
      setError("Please select a PDF file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setLoading(true); // Show loading spinner
    try {
      const uploadResponse = await axios.post("https://api.lordapdf.com/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (uploadResponse.status === 200 && uploadResponse.data.file_id) {
        const uploadedFileId = uploadResponse.data.file_id;

        const response = await axios.post("https://api.lordapdf.com/compress", {
          file_id: uploadedFileId,
        });

        if (response.status === 200 && response.data.file_id) {
          setFileId(response.data.file_id);
         // alert("File compressed successfully!");
        } else {
          throw new Error("Compress operation failed.");
        }
      } else {
        throw new Error("File upload failed.");
      }
    } catch (error) {
      setError("Error occurred while compressing the file. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDownload = async () => {
    setLoading(true); // Show loading spinner
    try {
      const response = await axios.get(`https://api.lordapdf.com/download/${fileId}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError("Error occurred while downloading the file. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <>

    <Helmet>
  <title>Compress PDFs Online | Lord-a-PDF</title>
  <meta
    name="description"
    content="Reduce the file size of your PDFs without compromising quality. Free and easy-to-use PDF compression."
  />
	<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7001854000154125"
     crossorigin="anonymous"></script>
  <meta name="keywords" content="compress pdf, reduce pdf size, pdf compressor, lord-a-pdf" />
  <meta property="og:title" content="Compress PDFs Online | Lord-a-PDF" />
  <meta property="og:description" content="Reduce the file size of your PDFs without compromising quality." />
  <meta property="og:image" content="https://www.yourwebsite.com/assets/compress-og-image.png" />
  <meta property="og:url" content="https://www.yourwebsite.com/compress" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Compress PDFs Online | Lord-a-PDF" />
  <meta name="twitter:description" content="Reduce the file size of your PDFs without compromising quality." />
</Helmet>

      <div id="header">
        <h1>Lord-a-PDF</h1>
        <h5>Free &#9900; Fast &#9900; Easy &#9900; No Login/Signup</h5>
        <h6>
          <i>(basically a lord)</i>
        </h6>
        <nav className="navbar">
          <button className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
            ☰
          </button>
          <ul className={`nav-links ${isNavOpen ? "open" : ""}`}>
            <li><a href="/">Home</a></li>
            <li><a href="/#/merge">Merge</a></li>
            <li><a href="/#/split">Split</a></li>
            <li><a href="/#/watermark">Watermark</a></li>
            <li><a href="/#/protect">Protect</a></li>
            <li><a href="/#/ocr">OCR</a></li>
            <li><a href="/#/compress">Compress</a></li>
          </ul>
        </nav>
      </div>

      <div id="content">
        <div className="operation-page">
          <header className="page-header">
            <h1>Compress PDF</h1>
          </header>
          <div className="main-content">
            <div className="operation-section">
              <p>Upload a PDF to reduce its file size.</p>
              <label htmlFor="file-upload" className="custom-file-upload">
                Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".pdf"
                onChange={handleFileSelection}
              />
              {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              <button onClick={handleCompress}>Compress File</button>
              {error && <p className="error">{error}</p>}
              {fileId && (
                <div>
                  <p>Your compressed file is ready:</p>
                  <button onClick={handleDownload}>Download Compressed File</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div id="footer">
        <p>&copy; 2024 IndianMadeSOL</p>
      </div>
    </>
  );
};

export default CompressPage;
