import React, { useState } from "react";
import axios from "axios";
import "./OperationPage.css";
import { Helmet } from "react-helmet";

const MergePage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [error, setError] = useState("");
  const [fileId, setFileId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files).filter((file) =>
      file.name.endsWith(".pdf")
    );

    if (files.length !== event.target.files.length) {
      setError("Only PDF files are allowed.");
      return;
    }

    setError("");
    setSelectedFiles(files);
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      setError("Please select files to upload.");
      return;
    }

    setError("");
    setLoading(true); // Show loader
    const uploaded = [];

    for (const file of selectedFiles) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post("https://api.lordapdf.com/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status === 200 && response.data.file_id) {
          uploaded.push({ name: file.name, id: response.data.file_id });
        } else {
          throw new Error(`Unexpected response: ${JSON.stringify(response.data)}`);
        }
      } catch (error) {
        console.error(`Failed to upload file ${file.name}:`, error.message);
        setError(`Failed to upload file: ${file.name}`);
        setLoading(false); // Hide loader on error
        return; // Stop further uploads on error
      }
    }

    setUploadedFiles((prev) => [...prev, ...uploaded]);
    setSelectedFiles([]);
    setLoading(false); // Hide loader
  };

  const handleMerge = async () => {
    if (uploadedFiles.length < 2) {
      setError("Please upload at least two files to merge.");
      return;
    }

    setLoading(true); // Show loader
    try {
      const response = await axios.post("https://api.lordapdf.com/merge", {
        file_ids: uploadedFiles.map((file) => file.id),
      });

      if (response.status === 200 && response.data.file_id) {
        setFileId(response.data.file_id);
       // alert("Files merged successfully!");
      } else {
        throw new Error("Merge operation failed. No file ID provided.");
      }
    } catch (error) {
      setError("Error occurred while merging files. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleDownload = async () => {
    setLoading(true); // Show loader
    try {
      const response = await axios.get(`https://api.lordapdf.com/download/${fileId}`, {
        responseType: "blob", // Important for file downloads
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setError("Error occurred while downloading the file. Please try again.");
      console.error(error.response || error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    

    <>

    <Helmet>
  <title>Merge PDFs Online | Lord-a-PDF</title>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7001854000154125"
     crossorigin="anonymous"></script>
  <meta
    name="description"
    content="Easily merge multiple PDF files into one document with Lord-a-PDF. Free, fast, and easy to use."
  />
  <meta name="keywords" content="merge pdf, combine pdf, pdf merger, lord-a-pdf" />
  <meta property="og:title" content="Merge PDFs Online | Lord-a-PDF" />
  <meta property="og:description" content="Easily merge multiple PDF files into one document with Lord-a-PDF." />
  <meta property="og:image" content="https://www.yourwebsite.com/assets/merge-og-image.png" />
  <meta property="og:url" content="https://www.yourwebsite.com/merge" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Merge PDFs Online | Lord-a-PDF" />
  <meta name="twitter:description" content="Easily merge multiple PDF files into one document with Lord-a-PDF." />
</Helmet>
      <div id="header">
        <h1>Lord-a-PDF</h1>
        <h5>Free &#9900; Fast &#9900; Easy &#9900; No Login/Signup</h5>
        <h6>
          <i>(basically a lord)</i>
        </h6>
        <nav className="navbar">

        	<button className="nav-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>
            ☰
          </button>
          <ul className={`nav-links ${isNavOpen ? "open" : ""}`}>

          
            <li><a href="/">Home</a></li>
            <li><a href="/#/merge">Merge</a></li>
            <li><a href="/#/split">Split</a></li>
            <li><a href="/#/watermark">Watermark</a></li>
            <li><a href="/#/protect">Protect</a></li>
            <li><a href="/#/ocr">OCR</a></li>
            <li><a href="/#/compress">Compress</a></li>
          </ul>
        </nav>
      </div>

      <div id="content">
        <div className="operation-page">
          <header className="page-header">
            <h1>Merge PDFs</h1>
          </header>
          <div className="main-content">
            <div className="operation-section">
              <p>Upload multiple PDF files to merge them into a single document.</p>
              <label htmlFor="file-upload" className="custom-file-upload">
                Choose Files
              </label>
              <input
                id="file-upload"
                type="file"
                multiple
                accept=".pdf"
                onChange={handleFileSelection}
              />
              {selectedFiles.length > 0 && (
                <p>{selectedFiles.length} file(s) selected</p>
              )}
              <button onClick={handleFileUpload}>Upload Files</button>
              {error && <p className="error">{error}</p>}
              <div className="uploaded-files-grid">
                {uploadedFiles.map((file, index) => (
                  <div key={index} className="file-item">
                    📄 {file.name}
                  </div>
                ))}
              </div>
              <button onClick={handleMerge} disabled={uploadedFiles.length < 2}>
                Merge Files
              </button>
              {fileId && (
                <div>
                  <p>Your merged file is ready:</p>
                  <button onClick={handleDownload}>Download Merged File</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}

      <div id="footer">
        <p>&copy; 2024 IndianMadeSOL</p>
      </div>
    </>
  );
};

export default MergePage;
